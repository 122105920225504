<template>
  <div>
    <div class="top-header">
      <div class="tp-bg">
        <img src="../assets/yzlogo.png" class="imglogo" alt="" />
        <div class="cnTitle">
          让 更 多 的 组 织 优 秀 起 来 让 更 多 的 人 高 贵 起 来
        </div>
        <!-- <div class="enTitle">
          Make More Organizations Outstanding Make More People Noble
        </div> -->
      </div>
      <div class="segment">
        <router-link to="/one" @click.native="changeTab(1)">
          <div class="item">
            <img
              :src="tabIndex == 1?imgOneS:imgOneU"
              style="width: 48px; height: 40px"
              alt=""
            />
            <div class="item-title" :style="tabIndex == 1?'color:#A13A30;':'color:white;'">公司概况</div>
          </div>
        </router-link>
        <router-link to="/two" @click.native="changeTab(2)">
          <div class="item">
            <img
              :src="tabIndex == 2?imgTwoS:imgTwoU"
              style="width: 48px; height: 40px"
              alt=""
            />
            <div class="item-title" :style="tabIndex == 2?'color:#A13A30;':'color:white;'">管理咨询</div>
          </div>
        </router-link>
        <router-link to="/three" @click.native="changeTab(3)">
          <div class="item">
            <img
              :src="tabIndex == 3?imgThreeS:imgThreeU"
              style="width: 48px; height: 40px"
              alt=""
            />
            <div class="item-title" :style="tabIndex == 3?'color:#A13A30;':'color:white;'">数据科技</div>
          </div>
        </router-link>
        <router-link to="/four" @click.native="changeTab(4)">
          <div class="item">
            <img
              :src="tabIndex == 4?imgFourS:imgFourU"
              style="width: 48px; height: 40px"
              alt=""
            />
            <div class="item-title" :style="tabIndex == 4?'color:#A13A30;':'color:white;'">培训服务</div>
          </div>
        </router-link>
        <router-link to="/five" @click.native="changeTab(5)">
          <div class="item">
            <img
              :src="tabIndex == 5?imgFiveS:imgFiveU"
              style="width: 48px; height: 40px"
              alt=""
            />
            <div class="item-title" :style="tabIndex == 5?'color:#A13A30;':'color:white;'">国网案例</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="content">
      <!-- <transition
        name="custom"
        enter-active-class="animate__animated animate__rubberBand"
      > -->
        <router-view></router-view>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      tabIndex:1,
      imgOneU:require('../assets/1u.png'),
      imgOneS:require('../assets/1s.png'),
      imgTwoU:require('../assets/2u.png'),
      imgTwoS:require('../assets/2s.png'),
      imgThreeU:require('../assets/3u.png'),
      imgThreeS:require('../assets/3s.png'),
      imgFourU:require('../assets/4u.png'),
      imgFourS:require('../assets/4s.png'),
      imgFiveU:require('../assets/5u.png'),
      imgFiveS:require('../assets/5s.png')
    };
  },
  components: {},
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeTab(index){
      this.tabIndex = index
      console.log(index)
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  //   height: 80vh;
}
.top-header {
  width: 100%;
  height: 301px;
  background: url("../assets/tpbg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .tp-bg {
    //   background: black;
    height: 208px;
    .imglogo {
      width: 50px;
      position: absolute;
      top: 11px;
      right: 25px;
    }
  }
  .item {
    width: 20%;
    float: left;
    .item-title {
      color: white;
      font-size: 15px;
    }
    .item-enTitle {
      color: white;
      font-size: 12px;
    }
  }
  .segment {
    width: 100%;
    height: 103px;
    //   background: gray;
  }
  .cnTitle {
    color: white;
    padding-top: 97px;
    font-size: 12px;
  }
  .enTitle {
    color: white;
    font-size: 15px;
  }
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
