<template>
  <div style="width:100%;">
      <img src="../assets/2.jpg" class="cimg" alt="">
      <img src="../assets/3.jpg" class="cimg" alt="">
      <!-- <img src="../assets/4.jpg" class="cimg" alt=""> -->
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {

    }
  },
  components: {
  },
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang="less" scoped>
.cimg {
    width: 100%;
    min-height: 400px;
    object-fit: cover;
}
</style>
