import Vue from "vue";
import Router from 'vue-router'
import Tab from '@/view/tab'
import One from '@/view/one'
import Two from '@/view/two'
import Three from '@/view/three'
import Four from '@/view/four'
import Five from '@/view/five'
// import Six from '@/view/six'
// import Seven from '@/view/seven'

Vue.use(Router)
export default new Router({
    routes:[
        {
            path:'/',
            name:'main',
            component:Tab,
            redirect:'/one',
            children:[
                {
                    path:'/one',
                    name:'one',
                    component:One
                },
                {
                    path:'/two',
                    name:'two',
                    component:Two
                },
                {
                    path:'/three',
                    name:'three',
                    component:Three
                },
                {
                    path:'/four',
                    name:'four',
                    component:Four
                },
                {
                    path:'/five',
                    name:'five',
                    component:Five
                }

            ]
        },
        
    ]
})